import styled from "styled-components";
import Slider from "react-slick";
import Link from "next/link";

import { RegionData } from "utils/constants";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  centerPadding: "100px",
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: true,
      },
    },
  ],
};

const RegionCard = () => {
  return (
    <Container>
      <Title>Yakınındaki bölgelere göz at</Title>
      <StyledSlider {...settings}>
        {RegionData.map((region) => (
          <Link href={`/kamp-alanlari?region=${region.id}`} passHref key={region.id}>
            <Card>
              <Image
                src={region.image}
                alt={region.imageAlt}
                width={1600}
                height={900}
              />
              <Content>
                <ContentTitle>{region.name}</ContentTitle>
                {/* <ContentDesc> 200 Km uzaklikta</ContentDesc> */}
              </Content>
            </Card>
          </Link>
        ))}
      </StyledSlider>
    </Container>
  );
};

const Container = styled.div`
  text-align: left;
  margin-bottom: 96px;
`;

const StyledSlider = styled(Slider)`
  margin-top: 24px;

  .slick-dots li button:before {
    color: #868F97 !important;
    font-size: 10px !important;
    opacity: 1 !important;
  }

  .slick-dots li.slick-active button:before {
    color: ${(props) => `${props.theme.main.primary} !important`};
  }
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: 600;
  font-weight: 600;
  text-align: left;
`;

const Card = styled.div`
  cursor: pointer;
  border-radius: 12px;
  width: 100%;
  flex: 1;
  margin-right: 20px;
  height: 500px;
  background: rgb(222, 49, 81);
  &:last-child {
    margin-right: 0px;
  }
  @media (max-width: ${(props) => props.theme.size.laptopL}) {
    height: 400px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-height: 300px;
  @media (max-width: ${(props) => props.theme.size.laptopL}) {
    max-height: 250px;
  }
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px;
`;
const ContentTitle = styled.span`
  font-size: 35px;
  font-weight: 600;
  line-height: 39px;
  color: white;
  padding-bottom: 10px;
`;
const ContentDesc = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: white;
`;

export default RegionCard;
