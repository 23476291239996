const iconTitles = {
  "title": "Olanaklar",
  "facility": "Tesis",
  "picnic_table": "Piknik Masası",
  "beach": "Deniz",
  "fountain": "Çeşme",
  "toilets": "Tuvalet",
  "no_fire": "Ateş Yakılmaz",
  "wild_animals": "Yabani Hayvan",
  "antenna": "Sinyal Var",
  "bus": "Toplu Taşıma",
  "car_park": "Otopark",
  "caravan": "Karavan",
  "fishing": "Balıkçılık",
  "wallet": "Ücretli",
  "trekking": "Trekking",
  "wood": "Odun",
  "facility_badge": "TESİS",
  "paid_badge": "ÜCRETLİ",
  "free_badge": "ÜCRETSİZ",
  "private_badge": "ÖZEL İŞLETME"
}

const CategoryTitles = {
  "title": "Kategoriler",
  "forest": "Ormanlık",
  "lake": "Göl",
  "beach": "Sahil",
  "plateau": "Yayla",
  "hilly": "Dağlık",
  "natural": "Doğal"
}

const RegionTitles = {
  "karadeniz": "Karadeniz",
  "marmara": "Marmara",
  "ege": "Ege",
  "akdeniz": "Akdeniz",
  "dogu_anadolu": "Doğu Anadolu",
  "ic_anadolu": "İç Anadolu",
  "guney_anadolu": "Güneydoğu Anadolu"
}

// export const IconSet = [
//   {id: '13', icon: '/images/icons/money.png', title: iconTitles.wallet},
//   {id: '9', icon: '/images/icons/bus.png', title: iconTitles.bus},
//   {id: '1', icon: '/images/icons/cabin.png', title: iconTitles.facility},
//   {id: '10', icon: '/images/icons/car_park.png', title: iconTitles.car_park},
//   {id: '11', icon: '/images/icons/caravan.png', title: iconTitles.caravan},
//   {id: '4', icon: '/images/icons/tap.png', title: iconTitles.fountain},
//   {id: '5', icon: '/images/icons/toilet-paper.png', title: iconTitles.toilets},
//   {id: '7', icon: '/images/icons/track.png', title: iconTitles.wild_animals},
//   {id: '2', icon: '/images/icons/picnic.png', title: iconTitles.picnic_table},
//   {id: '3', icon: '/images/icons/sun.png', title: iconTitles.beach},
//   {id: '6', icon: '/images/icons/no-fire.png', title: iconTitles.no_fire},
//   {id: '8', icon: '/images/icons/antenna.png', title: iconTitles.antenna},
//   {id: '12', icon: '/images/icons/fish.png', title: iconTitles.fishing},
//   {id: '14', icon: '/images/icons/trekking.png', title: iconTitles.trekking},
//   {id: '15', icon: '/images/icons/wood.png', title: iconTitles.wood},
// ]

export const IconSet: any = {
  "1": {icon: '/images/icons/cabin.png', title: iconTitles.facility},
  "2": {icon: '/images/icons/picnic.png', title: iconTitles.picnic_table},
  "3": {icon: '/images/icons/sun.png', title: iconTitles.beach},
  "4": {icon: '/images/icons/tap.png', title: iconTitles.fountain},
  "5": {icon: '/images/icons/toilet-paper.png', title: iconTitles.toilets},
  "6": {icon: '/images/icons/no-fire.png', title: iconTitles.no_fire},
  "7": {icon: '/images/icons/track.png', title: iconTitles.wild_animals},
  "8": {icon: '/images/icons/antenna.png', title: iconTitles.antenna},
  "9": {icon: '/images/icons/bus.png', title: iconTitles.bus},
  "10": {icon: '/images/icons/car_park.png', title: iconTitles.car_park},
  "11": {icon: '/images/icons/caravan.png', title: iconTitles.caravan},
  "12": {icon: '/images/icons/fish.png', title: iconTitles.fishing},
  "13": {icon: '/images/icons/money.png', title: iconTitles.wallet},
  "14": {icon: '/images/icons/trekking.png', title: iconTitles.trekking},
  "15": {icon: '/images/icons/wood.png', title: iconTitles.wood},
}

export const regions = [
  {id: 3, title: RegionTitles.karadeniz, isSelected: false},
  {id: 2, title: RegionTitles.marmara, isSelected: false},
  {id: 1, title: RegionTitles.ege, isSelected: false},
  {id: 4, title: RegionTitles.akdeniz, isSelected: false},
  {id: 6, title: RegionTitles.dogu_anadolu, isSelected: false},
  {id: 5, title: RegionTitles.ic_anadolu, isSelected: false},
  {id: 7, title: RegionTitles.guney_anadolu, isSelected: false},
];

export const categories = [
  {id: '1', title: CategoryTitles.forest, isSelected: false},
  {id: '2', title: CategoryTitles.lake, isSelected: false},
  {id: '3', title: CategoryTitles.beach, isSelected: false},
  {id: '4', title: CategoryTitles.plateau, isSelected: false},
  {id: '5', title: CategoryTitles.hilly, isSelected: false},
  {id: '6', title: CategoryTitles.natural, isSelected: false},
];

export const attributes = [
  {id: '13', title: iconTitles.wallet, isSelected: false},
  {id: '9', title: iconTitles.bus, isSelected: false},
  {id: '1', title: iconTitles.facility, isSelected: false},
  {id: '10', title: iconTitles.car_park, isSelected: false},
  {id: '11', title: iconTitles.caravan, isSelected: false},
  {id: '4', title: iconTitles.fountain, isSelected: false},
  {id: '5', title: iconTitles.toilets, isSelected: false},
  {id: '7', title: iconTitles.wild_animals, isSelected: false},
  {id: '2', title: iconTitles.picnic_table, isSelected: false},
  {id: '3', title: iconTitles.beach, isSelected: false},
  {id: '6', title: iconTitles.no_fire, isSelected: false},
  {id: '8', title: iconTitles.antenna, isSelected: false},
  {id: '12', title: iconTitles.fishing, isSelected: false},
  {id: '14', title: iconTitles.trekking, isSelected: false},
  {id: '15', title: iconTitles.wood, isSelected: false},
];

export const RegionData = [
  {image: '/images/regions/marmara.webp', name: 'Marmara Bölgesi', imageAlt: 'marmara', id: '2'},
  {image: '/images/regions/ege.webp', name: 'Ege Bölgesi', imageAlt: 'ege', id: '1'},
  {image: '/images/regions/akdeniz.webp', name: 'Akdeniz Bölgesi', imageAlt: 'akdeniz', id: '4'},
  {image: '/images/regions/karadeniz.webp', name: 'Karadeniz Bölgesi', imageAlt: 'karadeniz', id: '3'}
]